<template>
  <b-card>
    <b-row>
      <b-col md="4">
        <b-card title="Logotipo do Sistema">
          <b-media>
            <b-media-aside>
              <b-link>
                <span class="brand-logo">
                  <b-img
                    :src="images.logo ? images.logo : appLogoImage"
                    alt="logo"
                    variant="light-primary"
                    badge
                    class="badge-minimal"
                    badge-variant="success"
                    height="80"
                    ref="refPreviewEl"
                    rounded
                  />
                </span>
              </b-link>
            </b-media-aside>
            <b-media-body class="mt-75 ml-75">
              <!-- Upload -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.refInputEl.$el.click()"
              >
                Trocar logo
              </b-button>

              <b-form-file ref="refInputEl" accept=".jpg, .png," :hidden="true" plain @input="inputImageRenderer" />

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="salvarlogo"
                :disable="inputImageRenderer"
              >
                Salvar logo
              </b-button>

              <!-- Limpar -->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                size="sm"
                class="mb-75 mr-75"
                @click="onRemoved"
              >
                Limpar logo
              </b-button>
              <!--/ reset -->
              <b-card-text
                >É permitido a inserção de imagens JPG, GIF ou PNG<br />Dimensão recomendada 40px por 40px<br /><span
                  class="text-danger"
                  >A Logotipo será apresentada quando o menu lateral estiver recolhido</span
                >
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>

        <b-card title="Logomarca do Sistema">
          <b-media>
            <b-media-aside>
              <b-link>
                <span class="brand-logo">
                  <b-img
                    :src="images.logoMarca ? images.logoMarca : appLogoImage"
                    alt="logo"
                    variant="light-primary"
                    badge
                    class="badge-minimal"
                    badge-variant="success"
                    height="80"
                    ref="refPreviewElLogoMarca"
                    rounded
                  />
                </span>
              </b-link>
            </b-media-aside>
            <b-media-body class="mt-75 ml-75">
              <!-- Upload -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.refInputElLogoMarca.$el.click()"
              >
                Trocar logo
              </b-button>
              <b-form-file
                ref="refInputElLogoMarca"
                accept=".jpg, .png,"
                :hidden="true"
                plain
                @input="inputImageRendererLogoMarca"
              />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="salvarlogomarca"
                :disable="inputImageRendererLogoMarca"
              >
                Salvar logo
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                size="sm"
                class="mb-75 mr-75"
                @click="onRemovedLogoMarca"
              >
                Limpar logo
              </b-button>
              <!--/ reset -->
              <b-card-text>
                É permitido a inserção de imagens JPG, GIF ou PNG<br />Dimensão recomendada 232px por 104px<br /><span
                  class="text-danger"
                  >A Logomarca será apresentada quando o menu lateral estiver expandido</span
                >
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>

        <b-card title="Logo do Relatório">
          <b-media>
            <b-media-aside>
              <b-link>
                <span class="brand-logo">
                  <b-img
                    :src="images.logoRelatorio ? images.logoRelatorio : appLogoImage"
                    alt="logo"
                    variant="light-primary"
                    badge
                    class="badge-minimal"
                    badge-variant="success"
                    height="80"
                    ref="refPreviewElRelatorio"
                    rounded
                  />
                </span>
              </b-link>
            </b-media-aside>
            <b-media-body class="mt-75 ml-75">
              <!-- Upload -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.refInputElRelatorio.$el.click()"
              >
                Trocar logo
              </b-button>

              <b-form-file
                ref="refInputElRelatorio"
                accept=".jpg, .png,"
                :hidden="true"
                plain
                @input="inputImageRendererRelatorio"
              />

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="salvarlogoRelatorio"
                :disable="inputImageRendererRelatorio"
              >
                Salvar logo
              </b-button>

              <!-- Limpar -->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                size="sm"
                class="mb-75 mr-75"
                @click="onRemovedRelatorio"
              >
                Limpar logo
              </b-button>
              <!--/ reset -->
              <b-card-text
                >É permitido a inserção de imagens JPG, GIF ou PNG<br />Dimensão recomendada 288px por 104px</b-card-text
              >
            </b-media-body>
          </b-media>
        </b-card>
        <b-card title="Logo da Calculadora Externa">
          <b-media>
            <b-media-aside>
              <b-link>
                <span class="brand-logo">
                  <b-img
                    :src="images.logoCalculadora ? images.logoCalculadora : appLogoImage"
                    alt="logo"
                    variant="light-primary"
                    badge
                    class="badge-minimal"
                    badge-variant="success"
                    height="80"
                    ref="refPreviewElCalculadora"
                    rounded
                  />
                </span>
              </b-link>
            </b-media-aside>
            <b-media-body class="mt-75 ml-75">
              <!-- Upload -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.refInputElCalculadora.$el.click()"
              >
                Trocar logo
              </b-button>

              <b-form-file
                ref="refInputElCalculadora"
                accept=".jpg, .png,"
                :hidden="true"
                plain
                @input="inputImageRendererCalculadora"
              />

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="salvarlogoCalculadora"
                :disable="inputImageRendererCalculadora"
              >
                Salvar logo
              </b-button>

              <!-- Limpar -->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                size="sm"
                class="mb-75 mr-75"
                @click="onRemovedCalculadora"
              >
                Limpar logo
              </b-button>
              <!--/ reset -->
              <b-card-text
                >É permitido a inserção de imagens JPG, GIF ou PNG<br />Dimensão recomendada 288px por 104px</b-card-text
              >
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>

      <b-col md="8">
        <b-card title="Informações">
          <validation-observer ref="formPersonaliza">
            <!-- form -->
            <b-form class="mt-2">
              <b-row>
                <!-- Razão Social -->
                <b-col sm="6">
                  <b-form-group label="Razão Social" label-for="razaoSocial">
                    <b-form-input
                      v-model="optionsLocal.razaoSocial"
                      name="razaoSocial"
                      placeholder="Razão Social"
                      rules="required"
                    />
                  </b-form-group>
                </b-col>

                <!-- telefone -->
                <b-col md="6">
                  <b-form-group label="Telefone" label-for="telefone">
                    <validation-provider #default="{ errors }" name="telefone" rules="required|celularTelefone">
                      <cleave
                        id="telefone"
                        v-model="optionsLocal.telefone"
                        class="form-control"
                        :raw="false"
                        :options="configuraCleaveTelefone()"
                        placeholder="Telefone"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- CNPJ -->
                <b-col md="6">
                  <b-form-group label-for="cpnj" label="CNPJ">
                    <validation-provider #default="{ errors }" name="cnpj" rules="required|cnpj">
                      <cleave
                        id="cnpj"
                        v-model="optionsLocal.cnpj"
                        class="form-control"
                        :raw="false"
                        :options="cleveCnpj"
                        placeholder="CNPJ"
                        rules="required"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="CEP" label-for="cep">
                    <validation-provider #default="{ errors }" name="cnpj" rules="required|cep">
                      <cleave
                        id="cep"
                        type="text"
                        placeholder="CEP"
                        v-model="optionsLocal.cep"
                        maxlength="10"
                        @keyup.native="buscarCEP"
                        :raw="false"
                        :options="cleaveCEP"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="5">
                  <b-form-group label="Logradouro" label-for="logradouro">
                    <b-form-input id="logradouro" type="text" placeholder="Logradouro" v-model="optionsLocal.logradouro" />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Nº" label-for="numero">
                    <b-form-input id="numero" type="text" placeholder="Número" v-model="optionsLocal.numero" />
                  </b-form-group>
                </b-col>
                <b-col md="5">
                  <b-form-group label="Bairro" label-for="bairro">
                    <b-form-input id="bairro" type="text" placeholder="Bairro" v-model="optionsLocal.bairro" />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <b-form-group label="UF" label-for="uf">
                    <b-form-select name="uf" v-model="optionsLocal.uf" :options="ufOpcoes"> </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Cidade" label-for="cidade">
                    <v-select-pt name="cidade" v-model="optionsLocal.cidade" :options="cidade" label="nome"></v-select-pt>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-col cols="12" class="p-0">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  style="width: 160px"
                  variant="primary"
                  class="mt-2 mr-1"
                  @click.prevent="salvar"
                >
                  Salvar
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  style="width: 160px"
                  variant="outline-secondary"
                  type="reset"
                  class="mt-2"
                  @click.prevent="limpar"
                >
                  Limpar
                </b-button>
              </b-col>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
  import { updatePersonalizacoes } from '@/utils/services/instituto'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import { $themeConfig } from '@themeConfig'
  import { celularTelefone, required } from '@validations'

  //form referencia e valida
  import { ref } from '@vue/composition-api'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'

  //rederizar imagens
  import {
    useInputImageRenderer,
    useInputImageRendererLogoMarca,
    useInputImageRendererRelatorio,
    useInputImageRendererCalculadora,
  } from '@core/comp-functions/forms/form-utils'

  // Para formatar o numero
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'

  // endereços
  import { Ufs } from '@/libs/utils'
  import { formatarParaNumber } from '@/utils/Functions/Formatacao/Formatacao'

  // notificações
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  import NaoAutorizadoModal from '@/views/error/nao-autorizado-modal.vue'

  export default {
    components: {
      Cleave,
      ValidationProvider,
      ValidationObserver,
      NaoAutorizadoModal,
      celularTelefone,
      required,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        images: JSON.parse(localStorage.getItem('images')),

        cleaveTelefone: {
          numericOnly: true,
          delimiters: ['(', ')', ' ', '-'],
          blocks: [0, 2, 0, 4, 6],
        },
        cleaveCelular: {
          numericOnly: true,
          delimiters: ['(', ')', ' ', ' ', '-'],
          blocks: [0, 2, 0, 1, 4, 4],
        },
        cleveCnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
        },
        cleaveCEP: {
          delimiters: ['.', '.', '-'],
          blocks: [2, 3, 3],
        },
        number: {},
        cidade: [],
        ufOpcoes: Ufs,
      }
    },
    methods: {
      buscarCEP() {
        if (this.optionsLocal?.cep?.length == 10) {
          let dadosPesquisaCep = {
            cep: this.optionsLocal.cep.replace(/\D/g, ''),
            institutoId: null,
          }

          useJwt
            .post(`cadastro/instituto/GetBuscaCepInstituto`, dadosPesquisaCep)
            .then((response) => {
              this.carregarCidadeEndereco(response.data.uf)
              this.optionsLocal = {
                ...this.optionsLocal,
                logradouro: response.data.logradouro,
                bairro: response.data.bairro,
                uf: response.data.uf,
                cidade: response.data.localidade,
              }
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      async carregarCidadeEndereco(item) {
        await useJwt
          .get(`utils/cidades/${item}`)
          .then((response) => {
            this.cidade = response.data
          })
          .catch((error) => {
            console.error(error)
          })
      },
      limpar() {
        this.optionsLocal = {}
        this.optionsLocal.id = JSON.parse(JSON.stringify(this.generalData.id))
      },
      onRemoved() {
        useJwt
          .delete(`cadastro/instituto/limparLogo`, this.optionsLocal.id)
          .then((response) => {
            const aux = {
              ...this.images,
              logo: null,
            }
            localStorage.setItem('images', JSON.stringify(aux))
            this.$root.$emit('atualizarLogoMenu', true)
            this.$router.go()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      onRemovedLogoMarca() {
        useJwt
          .delete(`cadastro/instituto/limparLogoMarca`, this.optionsLocal.id)
          .then((response) => {
            const aux = {
              ...this.images,
              logoMarca: null,
            }
            localStorage.setItem('images', JSON.stringify(aux))
            this.$root.$emit('atualizarLogoMenu', true)
            this.$router.go()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      onRemovedRelatorio() {
        useJwt
          .delete(`cadastro/instituto/limparLogoRelatorio`, this.optionsLocal.id)
          .then((response) => {
            const aux = {
              ...this.images,
              logoRelatorio: null,
            }
            localStorage.setItem('images', JSON.stringify(aux))
            this.$root.$emit('atualizarLogoMenu', true)
            this.$router.go()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      onRemovedCalculadora() {
        useJwt
          .delete(`cadastro/instituto/limparLogoCalculadora`, this.optionsLocal.id)
          .then((response) => {
            const aux = {
              ...this.images,
              logoCalculadora: null,
            }
            localStorage.setItem('images', JSON.stringify(aux))
            this.$root.$emit('atualizarLogoMenu', true)
            this.$router.go()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      salvarlogo() {
        const dataUrl = this.$refs.refPreviewEl.src
        if (dataUrl) {
          useJwt
            .post(`cadastro/instituto/cadastrarLogo`, {
              logo: dataUrl,
              institutoId: this.optionsLocal.id,
            })
            .then((response) => {
              this.$swal({
                title: 'Atenção!',
                text: 'Registro alterado com sucesso',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
                .then((response) => {
                  const aux = {
                    ...this.images,
                    logo: dataUrl,
                  }
                  localStorage.setItem('images', JSON.stringify(aux))
                  this.$root.$emit('atualizarLogoMenu', true)
                  this.$router.go()
                })
                .catch((error) => {
                  console.error(error)
                })
            })
        }
      },
      salvarlogomarca() {
        const dataUrl = this.$refs.refPreviewElLogoMarca.src
        if (dataUrl) {
          useJwt
            .post(`cadastro/instituto/cadastrarLogoMarca`, {
              logo: dataUrl,
              institutoId: this.optionsLocal.id,
            })
            .then((response) => {
              this.$swal({
                title: 'Atenção!',
                text: 'Registro alterado com sucesso',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
                .then((response) => {
                  const parametro = {
                    ...this.images,
                    logoMarca: dataUrl,
                  }
                  localStorage.setItem('images', JSON.stringify(parametro))
                  this.$root.$emit('atualizarLogoMenu', true)
                  this.$router.go()
                })
                .catch((error) => {
                  console.error(error)
                })
            })
        }
      },
      salvarlogoRelatorio() {
        const dataUrl = this.$refs.refPreviewElRelatorio.src
        if (dataUrl) {
          useJwt
            .post(`cadastro/instituto/cadastrarLogoRelatorio`, {
              logo: dataUrl,
              institutoId: this.optionsLocal.id,
            })
            .then((response) => {
              this.$swal({
                title: 'Atenção!',
                text: 'Registro alterado com sucesso',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
                .then((response) => {
                  const parametro = {
                    ...this.images,
                    logoRelatorio: dataUrl,
                  }
                  localStorage.setItem('images', JSON.stringify(parametro))
                  this.$root.$emit('atualizarLogoMenu', true)
                  this.$router.go()
                })
                .catch((error) => {
                  console.error(error)
                })
            })
        }
      },
      salvarlogoCalculadora() {
        if (this.optionsLocal.id == undefined) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erro: O instituto a ser configurado não está sendo identificado, reeinicie o sistema. ',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
        } else {
          const dataUrl = this.$refs.refPreviewElCalculadora.src
          if (dataUrl != '') {
            useJwt
              .post(`cadastro/instituto/cadastrarLogoCalculadora`, {
                logo: dataUrl,
                institutoId: this.optionsLocal.id,
              })
              .then((response) => {
                this.$swal({
                  title: 'Atenção!',
                  text: 'Registro alterado com sucesso',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then((response) => {
                    const aux = {
                      ...this.images,
                      logoCalculadora: dataUrl,
                    }
                    localStorage.setItem('images', JSON.stringify(aux))
                    this.$root.$emit('atualizarLogoMenu', true)
                    this.$router.go()
                  })
                  .catch((error) => {
                    console.error(error)
                  })
              })
          }
        }
      },
      salvar() {
        this.$refs.formPersonaliza.validate().then((success) => {
          if (success) {
            const insertData = {
              ...this.optionsLocal,
              cidade: this.optionsLocal.cidade.nome,
            }
            updatePersonalizacoes(this.optionsLocal.id, insertData)
              .then(() => {
                this.$swal({
                  title: 'Atenção!',
                  text: 'Registro alterado com sucesso',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch((error) => {
                console.error(error)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erro: ' + error.response.data.msg,
                      icon: 'BellIcon',
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-right',
                    timeout: 8000,
                  },
                )
              })
          }
        })
      },
      configuraCleaveTelefone() {
        let telefone = this.optionsLocal.telefone
        if (!telefone.length) {
          telefone = 0
        }
        const tamanhoReal = String(formatarParaNumber(telefone)).length
        if (tamanhoReal < 11) {
          return this.cleaveTelefone
        } else {
          return this.cleaveCelular
        }
      },
    },
    setup() {
      const { appLogoImage } = $themeConfig.app

      const refInputEl = ref(null)
      const refInputElLogoMarca = ref(null)
      const refInputElRelatorio = ref(null)
      const refInputElCalculadora = ref(null)
      const refPreviewEl = ref(null)
      const refPreviewElLogoMarca = ref(null)
      const refPreviewElRelatorio = ref(null)
      const refPreviewElCalculadora = ref(null)

      const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
        refPreviewEl.value.src = base64
      })

      const { inputImageRendererLogoMarca } = useInputImageRendererLogoMarca(refInputElLogoMarca, (base64) => {
        refPreviewElLogoMarca.value.src = base64
      })

      const { inputImageRendererRelatorio } = useInputImageRendererRelatorio(refInputElRelatorio, (base64) => {
        refPreviewElRelatorio.value.src = base64
      })

      const { inputImageRendererCalculadora } = useInputImageRendererCalculadora(refInputElCalculadora, (base64) => {
        refPreviewElCalculadora.value.src = base64
      })

      return {
        refInputEl,
        refInputElLogoMarca,
        refInputElRelatorio,
        refInputElCalculadora,
        refPreviewEl,
        refPreviewElLogoMarca,
        refPreviewElRelatorio,
        refPreviewElCalculadora,
        inputImageRenderer,
        inputImageRendererLogoMarca,
        inputImageRendererRelatorio,
        inputImageRendererCalculadora,
        appLogoImage,
      }
    },
  }
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
